import React from "react";
import { Icons } from "../../constants/icons";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { PagesRoutes } from "../../features/navigation/constants";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.top}>
          <Icons.Logotype className={styles.logo} />
          <div className={styles.top__right}>
            <LanguageToggle variant={"white"} />
          </div>
        </div>
        <span className={styles.divider} />
        <div className={styles.bottom}>
          <div className={styles.rightsText}>
            <Link to={PagesRoutes.COMMON.TERMS}>
              <span className={styles.rightsText__text}>Terms & Services</span>
            </Link>
          </div>
          <a href="mailto:info@creator-go.com" className={styles.mailLink}>
            Email: info@creator-go.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
