import { ReactComponent as HeroBannerCardIcon } from "../assets/icons/heroBannerCard.svg";
import { ReactComponent as LogoWhite } from "../assets/icons/logo_white.svg";
import { ReactComponent as PhoneNumber } from "../assets/icons/phoneNumber.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/email.svg";
import { ReactComponent as Logotype } from "../assets/icons/logotype.svg";
import { ReactComponent as UserProfile } from "../assets/icons/userProfile.svg";
import { ReactComponent as AgencyProfile } from "../assets/icons/agencyProfile.svg";
import { ReactComponent as Wallet } from "../assets/icons/streamer/wallet.svg";
import { ReactComponent as Document } from "../assets/icons/streamer/document.svg";
import { ReactComponent as Edit } from "../assets/icons/streamer/edit.svg";
import { ReactComponent as Home } from "../assets/icons/streamer/home.svg";
import { ReactComponent as Close } from "../assets/icons/streamer/close.svg";
import { ReactComponent as Error } from "../assets/icons/error.svg";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import { ReactComponent as Dollar } from "../assets/icons/agency/dollar.svg";
import { ReactComponent as Analytics } from "../assets/icons/agency/analytics.svg";
import { ReactComponent as ArrowCircle } from "../assets/icons/agency/arrow-circle.svg";
import { ReactComponent as Earth } from "../assets/icons/earth.svg";
import { ReactComponent as GoogleAuth } from "../assets/icons/google_auth.svg";

export const Icons = {
  HeroBannerCardIcon,
  LogoWhite,
  PhoneNumber,
  EmailIcon,
  Logotype,
  UserProfile,
  AgencyProfile,
  Wallet,
  Document,
  Edit,
  Home,
  Close,
  Error,
  Check,
  Dollar,
  Analytics,
  ArrowCircle,
  Earth,
  GoogleAuth,
};
